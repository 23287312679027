<template>
    <div class="module-add">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="block-title">{{ moduleName ? `Редактировать` : `Новый` }} модуль</h3>
                    <button
                        type="button"
                        class="btn-block-option close-modal"
                        @click="$modal.hide('module-add')"
                    >
                        ×
                    </button>
                </div>
                <div class="block-content content">
                    <div class="module-add__form-block form-group">
                        <label class="sr-only" for="name">Название модуля</label>
                        <input
                            class="module-add__form-input form-control"
                            maxlength="100"
                            id="name"
                            type="text"
                            v-model="form.name"
                            placeholder="Название модуля"
                        />
                    </div>
                    <div class="module-add__form-footer">
                        <button @click="onSubmit" class="v-btn module-add__form-submit-btn" type="submit">
                            {{ !moduleName ? 'Добавить' : 'Сохранить' }}
                        </button>
                        <button
                            class="v-btn v-btn--transparent module-add__form-reset-btn"
                            type="reset"
                            @click="$emit('close')"
                        >
                            Отменить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// import session from '@/api/session';
export default {
    name: 'CourseAdd',
    data () {
        return {
            form: {
                name: '',
            },
        }
    },
    props: {
        onAdd: {
            type: Function,
            required: false
        },
        onEdit: {
            type: Function,
            required: false
        },
        moduleName: {
            type: String,
            required: false
        }
    },
    methods: {
        onSubmit() {
            if (this.onEdit) {
                this.onEdit(this.form.name);
            } else {
                this.onAdd(this.form.name);
            }
            this.$emit('close');
        }
    },
    created () {
        if (this.moduleName) {
            this.form.name = this.moduleName;
        }
    }
}
</script>

<style lang="scss">
@import "#sass/v-style";

.v--modal-overlay .v--modal-box {
    overflow: visible;
}

.module-add {
    .modal-dialog {
        max-width: 528px;
        margin: 0;

        @include below($tablet) {
            max-width: none;
        }
    }

    .modal-content {
        padding: 40px;

        @include below($mobile) {
            padding: 15px;
        }
    }

    .modal-header {
        padding: 0;
        margin-bottom: 35px;

        @include below($mobile) {
            margin-bottom: 15px;
        }

        .block-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 22px;
            color: #000000;
        }
    }

    .close-modal {
        top: -13px;
        right: -42px;
    }

    .block-content {
        padding: 0;
    }

    &__form-block {
        position: relative;
        margin-bottom: 100px;
    }

    &__form-input {
        width: 100%;
        transition: border-color 0.3s;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: #989cae;
        height: 38px;
        padding: 8px 12px 10px;
        border: 1px solid #d5dbe4;
        border-radius: 2px;

        &::placeholder {
            color: $text-gray;
            font-size: 15px;
            line-height: 20px;
        }

        &:focus {
            outline: none;
        }
    }

    &__form-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__form-submit-btn {
        margin-right: 0px;
    }
}
</style>
